import ApiCalendar from "react-google-calendar-api";
import { useEffect, useState } from "react";
import AudioPlayer from "./components/AudioPlayer";

function Mixes() {
    const [tracks, setTracks] = useState([]);
  const [loaded, setLoaded] = useState(false);
  function getData() {
    return fetch(
      `${process.env.REACT_APP_API_DEV_SERVER}/api/mixes/?populate[mix_cover]=image&populate[mix_file]=audio`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN} `,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        let finalTL = data.data.map((d) => {
          let item =d.attributes;
          return {
            title: item.mix_name,
            src: item.mix_file.data.attributes.url,
            author: item.author,
            thumbnail: item.mix_cover.data.attributes.url,
            description: item.description,
          };
        });
        setTracks(finalTL);
        setLoaded(true);
      });
  }
  useEffect(() => {
    getData();
    
  }, []);
  return (
    <div className="mixes">
      {loaded? <AudioPlayer tracks={tracks}/>:null}
    </div>
  );
}

export default Mixes;