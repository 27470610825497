import { useRef, useState, useEffect } from "react";
//import { tracks } from "../data/tracks";
// import components
import DisplayTrack from "./DisplayTrack";
import Controls from "./Controls";
import ProgressBar from "./ProgressBar";
import TopBar from "./TopBar";
import "../styles/index.css";
import TrackList from "./TrackList";
const AudioPlayer = ({tracks}) => {
  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);
  // states
  //const [tracks, setTracks] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [trackIndex, setTrackIndex] = useState(0);
  const [currentTrack, setCurrentTrack] = useState(tracks[trackIndex]);
  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [count, setCount] = useState(0);
  const [mobile, setMobile] = useState(true);
  const trackListRef = useRef();
 

  // reference
  const audioRef = useRef();
  const progressBarRef = useRef();
  const nowPlayingRef = useRef();

  const isMountingRef = useRef(false);

  const handleNext = () => {
    if (trackIndex >= tracks.length - 1) {
      setTrackIndex(0);
      setCurrentTrack(tracks[0]);
    } else {
      setTrackIndex((prev) => prev + 1);
      setCurrentTrack(tracks[trackIndex + 1]);
    }
  };
  useEffect(() => {
    isMountingRef.current = true;
  }, []);
  useEffect(() => {
    console.log(windowWidth.current)
    if(windowWidth.current<601)setMobile(true)
      else setMobile(false)
  }, []);
 

  return (
    <>
      <div className="audio-player">
      {!mobile? <TrackList
            {...{
              tracks,
              currentTrack,
              setCurrentTrack,
              trackIndex,
              nowPlayingRef,
              isMountingRef,
              setTrackIndex,
              trackListRef,
              mobile
            

            }}
          />:null}
        <div className="inner">
          <DisplayTrack
            {...{
              currentTrack,
              audioRef,
              setDuration,
              progressBarRef,
              handleNext,
            }}
          />
          <Controls
            {...{
              audioRef,
              progressBarRef,
              duration,
              setTimeProgress,
              tracks,
              trackIndex,
              setTrackIndex,
              setCurrentTrack,
              handleNext,
            }}
          />
          <ProgressBar
            {...{ progressBarRef, audioRef, timeProgress, duration }}
          />
        {mobile? <TrackList
            {...{
              tracks,
              currentTrack,
              setCurrentTrack,
              trackIndex,
              nowPlayingRef,
              isMountingRef,
              setTrackIndex,
              trackListRef,
              mobile
            

            }}/>:null}
        </div>
      </div>
    </>
  );
};
export default AudioPlayer;
