import MenuItem from "./Menu";

const TopMenu = ({ item }) => {
  const aboutDropdown = () => (
    <contentMenu className="menuContent">About</contentMenu>
  );

 
 
  const contactDropdown = () => (
    <contentMenu className="menuContent">
      <li className="videos">
        <p>Facebook</p>
      </li>
      <li className="videos">
        <p>Instagram</p>
      </li>

      <li className="videos">
        <p>Phone</p>
      </li>
      <li className="videos">
        <p>Email</p>
      </li>
    </contentMenu>
  );
  const topMenuItems = [{ title: "Menu" }];

  return (
    <div o className="topMenu">
      {topMenuItems.map((item) => (
        <MenuItem {...{ item}} />
      ))}
    </div>
  );
};

export default TopMenu;
