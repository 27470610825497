import { useState, useEffect } from "react";
import SlideShow from "./components/SlideShow";
import { ReactComponent as List } from "./components/list-1-svgrepo-com.svg";
function Photos() {
  const [photos, setPhotos] = useState([]);
  const [groupID, setGroupID] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [list, setList] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(0);
  function getData() {
    return fetch(
      `${process.env.REACT_APP_API_DEV_SERVER}/api/photos/?populate[photos]=images&populate[mix_file]=audio`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN} `,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        let ph = data.data.filter((p) => {
          let amount = p.attributes.photos.data;
          if (amount.length != 1) return p;
        });
        setPhotos(ph);
        setGroupID(ph[0].id);
        setLoaded(true);
      });
  }
  useEffect(() => {
    if (loaded) setSelectedGroup(photos.filter((p) => p.id == groupID));
  }, [groupID]);
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="container photos">
      <div className="photolist">
        <div>
          <div className="list_toggle" onClick={() => setList(!list)}>
            <b>Events</b>
            <List />
          </div>
          {list ? (
            <div className="group_list">
              {photos.map((p) => (
                <div
                  className={p.id === groupID ? "selected_groups" : "groups"}
                  onClick={() => {
                    setGroupID(p.id);
                    setSelectedPhoto(0);
                  }}
                >
                  <img src={p.attributes.photos.data[0].attributes.url} />
                  <p>{p.attributes.name}</p>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
      <div>
        {selectedGroup[0] ? (
          <>
            <h1>{selectedGroup[0].attributes.name}</h1>
            {groupID ? (
              <SlideShow
                photos={selectedGroup[0].attributes.photos.data}
                {...{ selectedPhoto, setSelectedPhoto }}
              />
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
}

export default Photos;
