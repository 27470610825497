import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Screens/Home";
import TopMenu from "./Screens/components/TopMenu";
import Mixes from "./Screens/Mixes";
import Photos from "./Screens/Photos";
import Contact from "./Screens/Contact";
function App() {
  return (
    <BrowserRouter>
      <div className="App" id="App">
        <TopMenu />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mixes" element={<Mixes />} />
          <Route path="/photos" element={<Photos />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
