import { useEffect, useState } from "react";
import "../css/SlideShow.css";
function SlideShow({ photos, selectedPhoto, setSelectedPhoto }) {
  const prev = (currentPhoto, l) => {
    if (currentPhoto === 0) return setSelectedPhoto(photos.length - 1);
    setSelectedPhoto(currentPhoto - 1);
  };
  const next = (currentPhoto) => {
    if (currentPhoto === photos.length - 1) return setSelectedPhoto(0);
    setSelectedPhoto(currentPhoto + 1);
  };
  useEffect(() => {
    setSelectedPhoto(0);
  }, [photos]);
  return (
    <div className="slideshow">
      <div className="selected_photo_container">
        <div onClick={() => prev(selectedPhoto)}>
          <p>&#8592;</p>
        </div>
        <img
          className="selected_photo"
          src={photos[selectedPhoto].attributes.url}
        />
        <div onClick={() => next(selectedPhoto)}>
          <p>&#8594;</p>
        </div>
      </div>
      <div className="photo_previews">
        
          <h2>Photos</h2>
       
        <div className="photo_preview_container">
          {photos.map((photo, i) => (
            <img
              className={
                photo === photos[selectedPhoto]
                  ? "selected_photo_preview"
                  : "photo_preview"
              }
              onClick={() => setSelectedPhoto(i)}
              src={photo.attributes.url}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default SlideShow;
