import { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Menu } from "./menu-alt-svgrepo-com.svg";
import "../css/Menu.css";

const MenuItem = ({ item }) => {
  const [hover, setHover] = useState(false);
  const SplitMenuItem = ({ s }) => (
    <li className={s.style}>
      <Link to={s.left.url} target="_blank">
        <div className={s.right.name}>
          <p>{s.left.title}</p>
        </div>
      </Link>
      <Link to={s.right.url} target="_blank">
        <div className={s.right.name}>
          <p>{s.right.title}</p>
        </div>
      </Link>
    </li>
  );
  const FullMenuItem = ({ f }) => (
    <Link to={f.url}>
      <li className={f.style}>
        <p>{f.title}</p>
      </li>
    </Link>
  );
  const menuContent = [
    {
      name: "home",
      title: "About",
      style: "full",
      url: "/",
    },
    {
      name: "mixes",
      title: "Mixes",
      style: "full",
      url: "/mixes",
    },
    {
      name: "photos",
      title: "Photos",
      style: "full",
      url: "/photos",
    },
    {
      style: "split",
      left: {
        name: "youTube",
        title: "YouTube",
        url: "https://www.youtube.com/@djjspinna5476",
      },
      right: {
        name: "facebook",
        title: "Facebook",
        url: "https://www.facebook.com/DjJspinna",
      },
    },
    {
      name: "contact",
      title: "Contact",
      style: "full",
      url: "/contact",
    },
  ];
 const initialMenu = {
    name: "more",
    title: "More",
    style: "full",
    url: "/",
  }
  return (
    <div
      className="menuItem"
      onClick={() => setHover(!hover)}
    >
      {!hover ? (
        <FullMenuItem f={initialMenu} />
       
      ) : (
        <div className="menuContent">
          {menuContent.map((y) =>
            y.style === "full" ? (
              <FullMenuItem f={y} />
            ) : (
              <SplitMenuItem s={y} />
            )
          )}
        </div>
      )}
    </div>
  );
};

export default MenuItem;
