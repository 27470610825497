import { useEffect, useState } from "react";
import "./css/Contact.css";



function Contact({ events }) {
  const [name,setName] = useState(null)
  const [date,setDate] = useState(null)
  const [email,setEmail] = useState(null)
  const [phone,setPhone] = useState(null)
  const [description,setDescription] = useState(null)
const submitContact= ()=>{

}

  return (
    <div className="container contact">
    <form className="contactForm">
      <h2>Contact Form</h2>
      <li><label>Name:</label><input name="name"/></li>
      <li><label>Date of Event:</label><input name="date" type="date"/></li>
      <li><label>Contact Email:</label><input name="email"/></li>
      <li><label>Contact Phone:</label><input name="email"/></li>
      <li><label>Description of Event:</label><textarea name="description" /></li>

      <br/>
      <input type="submit" value="SUBMIT"/>
    </form>
    </div>
  );
}

export default Contact;
