import { useEffect, useState, useRef } from "react";
import { BsMusicNoteBeamed } from "react-icons/bs";
import { ReactComponent as List } from "./list-1-svgrepo-com.svg";

function TrackList({
  tracks,
  setCurrentTrack,
  mobile,
  trackIndex,
  nowPlayingRef,
  isMountingRef,
  setTrackIndex,
  trackListRef,
}) {
  const [tracklistView, setTracklistView] = useState(true);

  const scroll = function (ref) {
    let scrll =
      nowPlayingRef.current.offsetLeft -
      trackListRef.current.clientWidth / 2 -
      nowPlayingRef.current.clientWidth / 2;

    if (isMountingRef.current && !mobile) {
      trackListRef.current.scroll({
        left: scrll,
        behavior: "smooth",
      });
    } else if (isMountingRef.current && mobile) {
      trackListRef.current.scroll({
        top: scrll,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (tracklistView) scroll(nowPlayingRef);
  }, [trackIndex]);

  return (
    <div
      className={
        mobile
          ? tracklistView
            ? "open_sidebar_mobile"
            : "sidebar_mobile"
          : tracklistView
          ? "open_sidebar"
          : "sidebar"
      }
    >
      <div className="tracklistHeader">
        {tracklistView || mobile ? <p>Track List</p> : null}
        <div
          className="tracklistToggle"
          onClick={() => setTracklistView(!tracklistView)}
        >
          <List />
        </div>
      </div>
      <>
        <div
          className={mobile ? "tracklist_mobile" : "tracklist"}
          ref={trackListRef}
        >
          {tracklistView ? (
            <>
              {tracks.map((track, i) => (
                <div
                  className={
                    mobile
                      ? i === trackIndex
                        ? "selected_track_mobile"
                        : "track_mobile"
                      : i === trackIndex
                      ? "selected_track"
                      : "track"
                  }
                  ref={i === trackIndex ? nowPlayingRef : null}
                  id={i}
                  onClick={(e) => {
                    setCurrentTrack(tracks[i]);
                    setTrackIndex(i);
                  }}
                >
                  <div
                    className={
                      mobile ? "tracklist-info-mobil" : "tracklist-info"
                    }
                  >
                    {track.thumbnail ? (
                      <div className="tracklist-image">
                        {" "}
                        <img src={track.thumbnail} alt="audio avatar" />
                      </div>
                    ) : (
                      <div className="tracklist-icon-wrapper">
                        <span className="audio-icon">
                          <BsMusicNoteBeamed />
                        </span>
                      </div>
                    )}
                    <span>
                      <h3>{track.title}</h3>
                      <p>{track.author}</p>
                    </span>
                  </div>
                </div>
              ))}
            </>
          ) : null}
        </div>
      </>
    </div>
  );
}

export default TrackList;
